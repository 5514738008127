import { tns } from 'tiny-slider/src/tiny-slider.module.js';

class TestimonialsCarousels {
    constructor() {
        this.carouselEls = Array.from(document.querySelectorAll('[data-ncomponent="testimonials"]'));
        this.carousels = [];

        if (this.carouselEls) {
            this.init();
        }
    }

    init() {
        this.carouselEls.forEach((carouselEl) => {
            
            let carousel = tns({
                container: carouselEl,
                items: 1,
                slideBy: 1,
                autoplay: true,
                controls: true,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: true,
                nav: false,
                //navContainer: document.querySelector('[data-ncomponent="hero-nav"]'),
                //controlsContainer: document.querySelector('[data-ncarouselnav="shows"]'),
                touch: true,
                mouseDrag: false,
                prevButton: carouselEl.parentElement.querySelector('[data-ncontrol="prev"]'),
                nextButton: carouselEl.parentElement.querySelector('[data-ncontrol="next"]')
            });

            this.carousels.push(carousel);
        });
    }
}

export default new TestimonialsCarousels();
