class VacancyFilters {
    constructor() {
        this.radios = Array.from(document.querySelectorAll('[name="filters-category"]'));
        this.vacancyCards = Array.from(document.querySelectorAll('[data-nelement="vacancy"]'));
        this.selectedCategory = '';

        if (this.radios && this.vacancyCards) {
            this.init();
        }

        const urlParams = new URLSearchParams(window.location.search);
        const serviceCategory = urlParams.get('service');
        if (serviceCategory) {
            this.setSelectedCategory(serviceCategory);
        }

    }

    init() {
        this.radios.forEach((radio) => radio.addEventListener('change', () => this.updateSelectedCategory(radio.dataset.nvalue)));
    }

    setSelectedCategory (category) {
        const matchedEl =this.radios.find((r) => r.dataset.nvalue == category);
        
        if (matchedEl) {
            matchedEl.click();
        }
    }

    updateSelectedCategory(category) {
        this.selectedCategory = category;
        this.update();
    }

    update() {
        let matchedvacancyCards = this.vacancyCards.filter((projectCard) => {
            if (!this.selectedCategory) {
                return true;
            }

            return projectCard.dataset.ntags.split(',').includes(this.selectedCategory);
        });
        
        const unmatchedvacancyCards = this.vacancyCards.filter(projectCard => !matchedvacancyCards.includes(projectCard));

        matchedvacancyCards.forEach((projectCard) => {
            projectCard.classList.remove('hide', 'hidden');
        });

        unmatchedvacancyCards.forEach((projectCard) => {
            projectCard.classList.add('hide');
            setTimeout(() => projectCard.classList.contains('hide') && projectCard.classList.add('hidden'), 300);
        });
    }
}

export default new VacancyFilters();
