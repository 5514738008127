import Sharer from './modules/share/sharer';
import AutoResizeVideos from './modules/videos/autoresize';
import ContentModuleGallery from './modules/galleries/cm-gallery';
import Hero from './modules/hero/hero';
import TestimonialsCarousels from './modules/carousels/testimonials';
import VacancyFilters from './modules/vacancies/filters';
import 'picturefill';
import AOS from 'aos';

// Picture element HTML5 shiv
document.createElement("picture");

AOS.init({
    duration: 750
});
